// extracted by mini-css-extract-plugin
export var contactHeader = "modal-module--contactHeader--a2f66";
export var faqsHeader = "modal-module--faqsHeader--008a8";
export var hidden = "modal-module--hidden--d6f95";
export var modal = "modal-module--modal--0889a";
export var modalButtons = "modal-module--modalButtons--92153";
export var modalClose = "modal-module--modalClose--97fa1";
export var modalContent = "modal-module--modalContent--46332";
export var modalContentShare = "modal-module--modalContentShare--26032";
export var modalForm = "modal-module--modalForm--a2f9c";
export var modalInput = "modal-module--modalInput--3b3a9";
export var modalMain = "modal-module--modalMain--c5c75";
export var modalResetSize = "modal-module--modalResetSize--17601";
export var modalShareableSize = "modal-module--modalShareableSize--473d3";
export var modelDiv = "modal-module--modelDiv--f8b79";
export var partnersHeader = "modal-module--partnersHeader--44f3c";
export var partnersImages = "modal-module--partnersImages--89be6";
export var partnersImagesFour = "modal-module--partnersImagesFour--cc8d8";
export var partnersImagesFourNoop = "modal-module--partnersImagesFourNoop--348f3";