import React, { useState, useContext, useEffect } from "react";
import DebugView from "./../debug-view/index";
import FormDataContext from '../../context/FormDataContext';

import { useLocation } from "@reach/router";

import Footer from "../footer/index";

import Burger from '../mobile-sidebar/index';
import MenuLinks from '../menu/index';

import FaqModal from "../modal/faq-modal";
import ApiModal from "../modal/api-modal";
import PartnersModal from "../modal/partners-modal";
import ContactModal from "../modal/contact-modal";
import ResetModal from "../modal/reset-modal";
import ErrorModal from "../modal/error-modal";

import DefaultsStatusBar from "../defaultstatus";

import Logo from './../../images/logo.svg';

import './../../styles/index.scss';

import * as styles from './styles/index.module.scss';


const Layout = ({
    children
}) => {
    const [debugMode, setDebugMode] = useState(false);
    const [modalPartnersOpen, setPartnersModalOpen] = useState(false);
    const [modalFaqOpen, setFaqModalOpen] = useState(false);
    const [modalApiOpen, setApiModalOpen] = useState(false);
    const [modalContactOpen, setContactModalOpen] = useState(false);
    const [modalResetOpen, setResetModalOpen] = useState(false);
    const [modalErrorOpen, setErrorModalOpen] = useState(false);

    const location = useLocation();
    let path = location.pathname;
    //Replace all the forward slashes in path
    path = path.replaceAll(/\//g, '');

    useEffect(() => {
        window.addEventListener('defaultsStatus', () => {
            if ([
                '/',
                '/basic-information/',
                '/green-upgrades/',
                '/traditional-systems/'
            ].includes(window.location.pathname))
                return;
            if (window.defaultsStatus === 'error') {
                setErrorModalOpen(true);
                document.dispatchEvent(new Event("errorsEmail"));
            }
        });
    }, []);

    if(typeof window !== 'undefined') {
        window.toggleDebugWindow = () => {
            setDebugMode(!debugMode);
        }
    }

    return (
     
    <div>
        
        <Burger />

        <main className={styles.pageWrapper + (debugMode ? ` ` + styles.debug : '')}>
            <header className={styles.header}>
                <div>
                    <a href="https://greenup.rmi.org/" target="__blank">                       
                        <img
                            className={styles.logo}
                            src={Logo}
                            alt="RMI Green Upgrade Calculator" />
                    </a>
                    <MenuLinks/>
                </div>

                <Footer/>
            </header>
            <section className={styles.content}>

                <FaqModal modalFaqOpen={modalFaqOpen} setFaqModalOpen={setFaqModalOpen} />
                <ApiModal modalApiOpen={modalApiOpen} setApiModalOpen={setApiModalOpen} />
                <PartnersModal modalPartnersOpen={modalPartnersOpen} setPartnersModalOpen={setPartnersModalOpen} /> 
                <ContactModal modalContactOpen={modalContactOpen} setContactModalOpen={setContactModalOpen} /> 
                <ResetModal modalResetOpen={modalResetOpen} setResetModalOpen={setResetModalOpen} /> 
                <ErrorModal modalErrorOpen={modalErrorOpen} setErrorModalOpen={setErrorModalOpen} /> 

                <header className={`${styles.contentHeader}`}>
                    <div className={styles.logoContainer}>
                        <p className="main-heading">Green Upgrade Calculator</p>
                        <span className="version-number">Version 1.3</span>
                    </div>
                    <nav className={styles.contentNav}>
                        <ul>
                            <li><button onClick={e => setPartnersModalOpen(!modalPartnersOpen)}>Our Partners</button></li>
                            <li><button onClick={e => setFaqModalOpen(!modalFaqOpen)}>FAQ</button></li>
                            <li><button onClick={e => setApiModalOpen(!modalApiOpen)}>API</button></li>
                            <li><button onClick={e => setContactModalOpen(!modalContactOpen)}>Contact Us</button></li>
                            <li><button onClick={e => setResetModalOpen(!modalResetOpen)}>Reset Analysis</button></li>
                        </ul>
                    </nav>
                </header>
                <div className={`${styles.contentWrapper}`}>
                    {children}
                </div>
            </section>
            {debugMode ? <DebugView /> : null}

            {[
                'technical-details',
                'operation-and-maintenance',
                'upfront-costs-and-incentives',
                'financing'
            ].includes(path) ? <DefaultsStatusBar /> : null}

            <div className={styles.mobileFooter + ' mobile'}>
                <Footer/>
            </div>
        </main>
    </div>
    );
}

export default Layout;