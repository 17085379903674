// useFormData.js
import { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { HideIfKey, HideIfNoContext } from './functions/HideIfNoContext';
import { getField, getFields } from './FormFields';

import __ from 'lodash';

var showInputs = null;

let errorBody = null;

let errorTrigger = true;

const keysToRefresh = [
  "solargenpercentannualdemand",
  "solargenpercentannualexported",
  "solargen",
  "evrangelevel1",
  "bevmpge",
  "phevmpge",
  "ashp_calculated_heatloadservedwswitchoff",
  "solarcapco_perw"
];

let keysToSkip = [
  'basic-information',
  'green-upgrades',
  'traditional-systems',
  'share',
  'zipData',
  'rural-zip',
  'zip-greater-than-four',
  'active-solar-project',
  'first-character-zipcode',
  'atLeastOneUpgrade',
  'mirrorValues',
  'mode',
  'lsesData',
  'tariffsDataBau',
  'tariffsDataClean',
  'costData',
  'state',
  'debug_mode',
  'vehicle_type',
  'cleanelectricratetype',
  'defaultsLoaded'
];

keysToSkip = keysToSkip.concat(keysToRefresh);

// Declare abortController outside of the function so it persists across multiple calls
let abortController;

const useFormData = () => {

  var GATSBY_API_URL = process.env.GATSBY_API_URL;

  const [formData, setFormData] = useState(() => {
    const storedData = localStorage.getItem('form_data');
    return storedData ? JSON.parse(storedData) : {};
  });

  const [previousFormData, setPreviousFormData] = useState(() => {
    const storedData = localStorage.getItem('default_previous_form_data');
    return storedData ? JSON.parse(storedData) : {};
  });

  const [allFormData, setAllFormData] = useState(() => {
    const storedData = localStorage.getItem('default_all_form_data');
    return storedData ? JSON.parse(storedData) : {};
  });

  const addNameToInputtedValues = (name) => {
    if (!window.inputtedValues.includes(name)) {
      window.inputtedValues.push(name);
      window.dispatchEvent(new Event('inputtedValuesChange'));
    }
  }

  const removeNameFromInputtedValues = (name) => {
    const index = window.inputtedValues.indexOf(name);
    if (index > -1) {
      window.inputtedValues.splice(index, 1);
      window.dispatchEvent(new Event('inputtedValuesChange'));
    }
  }

  // Initialize window.inputtedValues from local storage
  useEffect(() => {
    // Check if window.inputtedValues is already initialized
    if (typeof window.inputtedValues === "undefined" || !window.inputtedValues) {
      const storedValues = localStorage.getItem('inputted_values');
      window.inputtedValues = storedValues ? JSON.parse(storedValues) : [];
    }

    // Update local storage whenever window.inputtedValues changes
    //Add event listener
    // window.dispatchEvent(new Event('inputtedValuesChange'));
    window.addEventListener('inputtedValuesChange', () => {
      localStorage.setItem('inputted_values', JSON.stringify(window.inputtedValues));
    });
  }, []);

  useEffect(() => {
    if(typeof formData.timeframe_t === "undefined") {
      if(formData?.vehiclefinance === 'Yes: Lease' && formData?.vehiclelease_yrs) {
        addOrUpdateFormData("timeframe_t", formData.vehiclelease_yrs);
      }else{
        addOrUpdateFormData("timeframe_t", 10);
      }
    }

    if(typeof formData.timeframe_h === "undefined") {
      if(formData?.solar === "Yes" || formData?.wthz === "Yes"){
        if(formData?.ashp !== "Yes" && formData?.hpwh !== "Yes" && formData?.range !== "Yes"){
          addOrUpdateFormData("timeframe_h", 25);
        }else{
          addOrUpdateFormData("timeframe_h", 20);
        }
      }else{
        addOrUpdateFormData("timeframe_h", 15);
      }
    }
  }, [formData]);

  useEffect(() => {
    if(formData?.solar === "Yes" || formData?.wthz === "Yes"){
      if(formData?.ashp !== "Yes" && formData?.hpwh !== "Yes" && formData?.range !== "Yes"){
        addOrUpdateFormData("timeframe_h", 25);
      }else{
        addOrUpdateFormData("timeframe_h", 20);
      }
    }else{
      addOrUpdateFormData("timeframe_h", 15);
    }
  }, [formData?.solar, formData?.wthz, formData?.ashp, formData?.hpwh, formData?.range]);

  useEffect(() => {
    if(formData?.vehiclefinance === 'Yes: Lease' && formData?.vehiclelease_yrs) {
      addOrUpdateFormData("timeframe_t", formData.vehiclelease_yrs);
    }else{
      addOrUpdateFormData("timeframe_t", 10);
    }
  }, [formData?.vehiclefinance, formData?.vehiclelease_yrs]);

  useEffect(() => {
    if(formData["wthz"] === "Yes" && formData["wthz_new"] === undefined) {
      addOrUpdateFormData("wthz_new", ["Adding insulation", "sealing air leaks"])
      addNameToInputtedValues("wthz_new");
    }

    if(formData["additional_home_upgrade"] === "Yes" && (formData["additional_home_upgrade_new"] === undefined || formData["additional_home_upgrade_new"] === "")) {
      addOrUpdateFormData("additional_home_upgrade_new", ["Electrical Panel Upgrade"])
      addNameToInputtedValues("additional_home_upgrade_new");
    }
  }, [formData["wthz"], formData["additional_home_upgrade"]])

  useEffect(() => {
    //Intended functionality is for this input to be disabled when ac_current is "No Mechanical Cooling" but this means the input can't be filled out and the user can't progress to a new page. Rather than reworking the whole input component setting it to be an empty string works
    if(formData["ac_current"] === "No Mechanical Cooling") {
      addOrUpdateFormData("ac_remaininglife", " ")
      addNameToInputtedValues("ac_remaininglife");
    }
  }, [formData["ac_current"]])

  useEffect(() => {
    if(formData["cooking_current"] === "I would not upgrade my stove") {
      addOrUpdateFormData("range_remaininglife", " ")
      addNameToInputtedValues("range_remaininglife");
    }
  }, [formData["cooking_current"]])


  const clearFormDataNoReload = (callback = false) => {

    setFormData({});
    setPreviousFormData({});
    setAllFormData({});

    // Clear inputted_values from localStorage
    localStorage.removeItem('inputted_values');

    // Reset window.inputtedValues to an empty array
    window.inputtedValues = [];
    window.dispatchEvent(new Event('inputtedValuesChange'));
    //Set a timeout to give everything chance to update

    if (typeof callback == 'function')
        callback();
  };


  const clearFormData = () => {
    clearFormDataNoReload();
    //Set a timeout to give everything chance to update
    setTimeout(() => {
      window.location = '/';
    },500);
  }

  const setZipcodeDataDebounced = debounce((zipcode, phevmake, phevmodel) => {
    fetch('/api/getZipcode', {
      method: 'POST',
      body: JSON.stringify({ zipcode: zipcode, phevmodel: phevmodel, phevmake: phevmake })
    })
      .then(response => response.json())
      .then(data => {
        setFormData((prevState) => ({
          ...prevState,
          zipData: data,
          "rural-zip": data['rural-zip'],
          "zip-greater-than-four": data['zip-greater-than-four'],
          "first-character-zipcode": data['first-character-zipcode'],
          "vehicle_type": data["vehicle_type"],
          "active-solar-project": data['active-solar-project'],
          "evrangelevel1": data["evrangelevel1"],
          "bevmpge": data["bevmpge"],
          "phevmpge": data["phevmpge"],
          "state": typeof data.state !== 'undefined' ? data.state : prevState.state
        }));
      });
  }, 300);

  const getLoadServingEntities = async () => {
    if (!formData?.zipcode)
      return;

    try {
      const response = await fetch('/api/Genability/getLoadServingEntities', {
        method: 'POST',
        body: JSON.stringify({ zipCode: formData.zipcode })
      });

      if (!response.ok)
        return null;

      const data = await response.json();

      addOrUpdateFormData('lsesData', data);

      return data;
    } catch (error) {
      console.error(`Failed to fetch LSES: ${error.message}`);
    }
  };

  const getBauTariffs = async () => {
    if (!formData?.zipcode || !formData?.electulrate || formData?.electulrate == 'State Average Rate')
      return;

    try {
      const response = await fetch('/api/Genability/getBauTariffsByLseId', {
        method: 'POST',
        body: JSON.stringify({ lseId: formData.electulrate, zipCode: formData.zipcode, request: formData })
      });

      if (!response.ok)
        return null;

      const data = await response.json();

      addOrUpdateFormData('tariffsDataBau', data);

      return data;

    } catch (error) {
      console.error(`Failed to fetch utilities: ${error.message}`);
    }
  };

  const getCleanTariffs = async () => {
    if (!formData?.zipcode || !formData?.electulrate || formData?.electulrate == 'State Average Rate')
      return;

    try {
      const response = await fetch('/api/Genability/getCleanerTariffsByLseId', {
        method: 'POST',
        body: JSON.stringify({ lseId: formData.electulrate, zipCode: formData.zipcode, request: formData })
      });

      if (!response.ok)
        return null;

      const data = await response.json();

      addOrUpdateFormData('tariffsDataClean', data);

      return data;

    } catch (error) {
      console.error(`Failed to fetch utilities: ${error.message}`);
    }
  };

  const setFixedRate = async () => {
    // electricratetype is the master tariff ID and the individual tariff ID, i.e. master_individual
    if (!formData?.zipcode || !formData.electricratetype)
      return;
  
    // Cancel the operation if the master tariff ID is not included
    if (!formData.electricratetype.includes("_"))
      return;

    try {
      const response = await fetch('/api/Genability/calculateFixedRate', {
        method: 'POST',
        body: JSON.stringify({ masterTariffId: formData.electricratetype.split("_")[0] })
      });

      if (!response.ok)
        return null;

      const data = await response.json();

      addOrUpdateFormData('rateelec_fixed_fe', data.display);
      addOrUpdateFormData('rateelec_fixed', data.value);

      addNameToInputtedValues('rateelec_fixed_fe');
      addNameToInputtedValues('rateelec_fixed');

      // getDefaultsFromApi(formData);

      return data;

    } catch (error) {
      console.error(`Failed to fetch utilities: ${error.message}`);
    }
  };

  const setFixedSolarRate = async () => {
    if (!formData?.zipcode || !formData.cleanelectricratetype)
      return;
  
    if (!formData.cleanelectricratetype.includes("_"))
      return;
    
    try {
      const response = await fetch('/api/Genability/calculateFixedRate', {
        method: 'POST',
        body: JSON.stringify({ masterTariffId: formData.cleanelectricratetype.split("_")[0] })
      });

      if (!response.ok)
        return null;

      const data = await response.json();

      addOrUpdateFormData('rateelec_fixedsolar', data.value);
      addOrUpdateFormData('rateelec_fixedsolar_fe', data.display);

      addNameToInputtedValues('rateelec_fixedsolar');
      addNameToInputtedValues('rateelec_fixedsolar_fe');
    } catch (error) {
      console.error(`Failed to calculate the fixed solar rate: ${error.message}`);
    }
  };

  const calculateGreenVolumetricRate = async () => {
    if (!formData?.zipcode || !formData.cleanelectricratetype)
      return;

    if (!formData.cleanelectricratetype.includes("_"))
      return;

    try {
      const response = await fetch('/api/Genability/calculateConsumptionRate', {
        method: 'POST',
        body: JSON.stringify({ masterTariffId: formData.cleanelectricratetype.split("_")[0] })
      });

      if (!response.ok)
        return null;

      const data = await response.json();

      addOrUpdateFormData('rateelec_retailgreen', data.value);
      addOrUpdateFormData('rateelec_retailgreen_fe', data.display);

      addNameToInputtedValues('rateelec_retailgreen');
      addNameToInputtedValues('rateelec_retailgreen_fe');
    } catch (error) {
      console.error(`Failed to calculate the green electric volumetric rate: ${error.message}`);
    }
  }

  const calculateNetMeteringRate = async () => {
    if (!formData?.zipcode || !formData.cleanelectricratetype)
      return;

    if (!formData.cleanelectricratetype.includes("_"))
      return;

    try {
      const response = await fetch('/api/Genability/calculateNetMeteringRate', {
        method: 'POST',
        body: JSON.stringify({ masterTariffId: formData.cleanelectricratetype.split("_")[0] })
      });

      if (!response.ok)
        return null;

      const data = await response.json();

      addOrUpdateFormData('rateelec_netmetering', data.value);
      addOrUpdateFormData('rateelec_netmetering_fe', data.display);

      addNameToInputtedValues('rateelec_netmetering');
      addNameToInputtedValues('rateelec_netmetering_fe');
    } catch (error) {
      console.error(`Failed to calculate the Net Metering rate: ${error.message}`);
    }
  }

  const calculateCleanHomeCosts = async () => {
    // cleanelectricratetype is the master tariff ID and the individual tariff ID, i.e. master_individual
    if (!formData?.zipcode || !formData.cleanelectricratetype)
      return;

    // Cancel the operation if the master tariff ID is not included
    if (!formData.cleanelectricratetype.includes("_"))
      return;

    let homeCleanCost = 0;

    try {
      const response = await fetch('/api/Genability/calculateCleanCost', {
        method: 'POST',
        body: JSON.stringify({ formData: formData, masterTariffId: formData.cleanelectricratetype.split("_")[0] })
      });

      if (!response.ok)
        return null;

      const data = await response.json();

      addOrUpdateFormData('home_clean_cost', data);
      addNameToInputtedValues('home_clean_cost');

      homeCleanCost = data;
    } catch (error) {
      console.error(`Failed to fetch utilities: ${error.message}`);
    }

    let homeTransportCleanCost = 0;

    try {
      const response = await fetch('/api/Genability/calculateCleanTransportCost', {
        method: 'POST',
        body: JSON.stringify({ formData: formData, masterTariffId: formData.cleanelectricratetype.split("_")[0] })
      });

      if (!response.ok)
        return null;

      const data = await response.json();

      addOrUpdateFormData('home_transport_clean_cost', data);
      addNameToInputtedValues('home_transport_clean_cost');

      homeTransportCleanCost = 0;

      addOrUpdateFormData('home_transport_cost', homeTransportCleanCost - homeCleanCost);
      addNameToInputtedValues('home_transport_cost');
    } catch (error) {
      console.error(`Failed to fetch utilities: ${error.message}`);
    }
  }

  const calculateVolumetricRate = async () => {      
    // electricratetype is the master tariff ID and the individual tariff ID, i.e. master_individual
    if (!formData?.zipcode || !formData.electricratetype)
      return;

    // Cancel the operation if the master tariff ID is not included
    if (!formData.electricratetype.includes("_"))
      return;

    try {
      const response = await fetch('/api/Genability/calculateConsumptionRate', {
        method: 'POST',
        body: JSON.stringify({ masterTariffId: formData.electricratetype.split("_")[0] })
      });

      if (!response.ok)
        return null;

      const data = await response.json();

      addOrUpdateFormData('rateelec_retail', data.value);
      addOrUpdateFormData('rateelec_retail_fe', data.display);

      addNameToInputtedValues('rateelec_retail');
      addNameToInputtedValues('rateelec_retail_fe');
    } catch (error) {
      console.error(`Failed to calculate the electric volumetric rate: ${error.message}`);
    }

    try {
      const response = await fetch('/api/Genability/calculateBAUCost', {
        method: 'POST',
        body: JSON.stringify({ formData: formData, masterTariffId: formData.electricratetype.split("_")[0] })
      });

      if (!response.ok)
        return null;

      const data = await response.json();

      addOrUpdateFormData('home_bau_cost', data);
      addNameToInputtedValues('home_bau_cost');
    } catch (error) {
      console.error(`Failed to fetch utilities: ${error.message}`);
    }
  };

  // Update the list of suppliers if the zipcode changes
  useEffect(() => {
    if (formData.zipcode) {
      const regex = /^(?:\d{5}(?:-\d{4})?)$/;
      
      if (regex.test(formData.zipcode)) {
        getLoadServingEntities();
        setZipcodeDataDebounced(formData.zipcode, formData.phevmake, formData.phevmodel);
      }
    }
  }, [formData.zipcode, formData.phevmake, formData.phevmodel]);
  

  useEffect(() => {
    if (formData.electulrate && formData.electulrate === 'State Average Rate') {
      removeNameFromInputtedValues('electulrate');
      removeNameFromInputtedValues('rateelec_retail');
      removeNameFromInputtedValues('rateelec_retail_fe');
      removeNameFromInputtedValues('rateelec_retailgreen');
      removeNameFromInputtedValues('rateelec_retailgreen_fe');
      removeNameFromInputtedValues('rateelec_fixed');
      removeNameFromInputtedValues('rateelec_fixed_fe');
      removeNameFromInputtedValues('rateelec_fixedsolar');
      removeNameFromInputtedValues('rateelec_fixedsolar_fe');
      removeNameFromInputtedValues('rateelec_netmetering');
      removeNameFromInputtedValues('rateelec_netmetering_fe');
    } else {
      getBauTariffs();
      getCleanTariffs();

    }
  }, [formData.electulrate]);

  useEffect(() => {
    if (formData.electricratetype) {
      calculateVolumetricRate();
      setFixedRate();
    }
  }, [formData.electricratetype]);

  useEffect(() => {
    if (formData.cleanelectricratetype) {
      // Frontend values
      calculateGreenVolumetricRate();
      calculateNetMeteringRate();
      setFixedSolarRate();
      // Backend values
      calculateCleanHomeCosts();
    }
  }, [formData.cleanelectricratetype]);

  useEffect(() => {
    if (formData.rateelec_fixed_fe && formData.rateelec_fixed)
      addOrUpdateFormData('rateelec_fixed', formData.rateelec_fixed_fe);
  }, [formData.rateelec_fixed_fe]);

  useEffect(() => {
    if (formData.rateelec_fixedsolar_fe && formData.rateelec_fixedsolar)
      addOrUpdateFormData('rateelec_fixedsolar', formData.rateelec_fixedsolar_fe);
  }, [formData.rateelec_fixedsolar_fe]);

  useEffect(() => {
    if (formData.rateelec_netmetering_fe && formData.rateelec_netmetering)
      addOrUpdateFormData('rateelec_netmetering', formData.rateelec_netmetering_fe);
  }, [formData.rateelec_netmetering_fe]);

  useEffect(() => {
    localStorage.setItem('form_data', JSON.stringify(formData));

    //Let's do a check to see if we need to remove any fields
    let formDataClone = formData;
    let keysDeleted = false;
    let validationUpdated = false;

    Object.keys(formData).forEach(key => {

      //Keys to skip
      if (keysToSkip.includes(key))
        return;

      if (HideIfKey(key, formData)) {
        let fieldObjects = getFields(key);
        delete formDataClone[key];
        keysDeleted = true;
        removeNameFromInputtedValues(key);

        // Check if all the field objects have the property 'mirrorValue' as true
        const allTrue = fieldObjects.every(fieldObj => fieldObj?.mirrorValue);

        if (allTrue) {
          fieldObjects.forEach((fieldObj) => {
            fieldObj?.mirrorValue.forEach((mirrorKey) => {
              if (formDataClone?.mirrorValues && typeof formDataClone?.mirrorValues[mirrorKey] != 'undefined') {
                delete formDataClone.mirrorValues[mirrorKey];
              }
            });
          });
        }
      }

    })

    /**
     * So of the hide ifs are getting too complicated.
     * Let's just add a flag to the formdata that we can use instead
     */

    //At least one transport upgrade
    let transportKeysToCheck = [
      'finalise-transport',
      'vehicle',
      'publictransit',
      'ebike',
      'chargeraccess',
      'phevmodel',
      'phevmake',
      'buy-electric-car'
    ];

    let atLeastOneUpgrade = transportKeysToCheck.some(key => formData[key] !== undefined && formData[key] !== "");

    if (formData['atLeastOneUpgrade'] !== atLeastOneUpgrade) {
      formDataClone['atLeastOneUpgrade'] = atLeastOneUpgrade;
      validationUpdated = true;
    }

    if (keysDeleted || validationUpdated)
      setFormData(formDataClone);

  }, [formData]);

  useEffect(() => {
    localStorage.setItem('default_previous_form_data', JSON.stringify(previousFormData));
  }, [previousFormData])

  useEffect(() => {
    localStorage.setItem('default_all_form_data', JSON.stringify(allFormData));
  }, [allFormData])

  const addOrUpdateFormData = (inputNames, values, mirrorValue = false, callback = false) => {
    setFormData((prevState) => {
      let newData = { ...prevState };
  
      if (Array.isArray(inputNames) && Array.isArray(values) && inputNames.length === values.length) {
        // Multiple inputNames with associated values
        inputNames.forEach((name, index) => {
          updateData(newData, name, values[index], mirrorValue);
        });
      } else {
        // Single inputName with a value (which could be an array)
        updateData(newData, inputNames, values, mirrorValue);
      }
  
      if (callback) {
        callback(newData);
      }
  
      return newData;
    });
  };
  
  const updateData = (data, inputName, value, mirror) => {
    if (!mirror) {
      data[inputName] = value;
    } else {
      let mirrorObj = (data?.mirrorValues) ? { ...data?.mirrorValues } : {};
      mirrorObj[inputName] = value;
      data.mirrorValues = mirrorObj;
    }
  };  

  const addDefault = (inputName, value) => {
    // if ( typeof formData[inputName] === 'undefined') {      
    //   addOrUpdateFormData(inputName, value)
    // }
  }

  /**
   * This has been duplicated into useResults. Needs splitting out at some point
   */
  const filterDataByInputted = (newData) => {
    let filteredData = window.inputtedValues ? window.inputtedValues.reduce((acc, key) => {
      if (typeof newData[key] !== 'undefined') {
        acc[key] = newData[key];
      }
      return acc;
    }, {}) : {};

    keysToSkip.forEach(k => {
      if (newData[k])
        filteredData[k] = newData[k];
    })

    return filteredData;
  }

  const replaceFormData = (newData) => {
    setFormData(newData);
    window.inputtedValues = Object.keys(newData);
    window.dispatchEvent(new Event('inputtedValuesChange'));
  }

  const getDefaultsFromApi = newData => {
    if (showInputs)
      clearTimeout(showInputs);
    if (!newData || __.isEqual(newData, previousFormData) && previousFormData.defaultsLoaded == true) {
      showInputs = setTimeout(() => {
        let el = document.querySelectorAll('.loading-div');
        window.defaultsStatus = 'uptodate';
        window.dispatchEvent(new Event('defaultsStatus'));
        if (el.length > 0) {
          el.forEach((el) => {
            el.classList.add('active');
          });
        }
      },1000);
      return;
    }

    setPreviousFormData(newData);

    // If there's an ongoing fetch request, abort it
    if (abortController) {
      abortController.abort();
    }

    // Create a new AbortController for the new fetch request
    abortController = new AbortController();
    // Extract values from formData that match the keys in window.inputtedValues
    const postData = filterDataByInputted(newData);

    //Turns percentage inputs into decimals
    const dividedArray = [];
    Object.keys(postData).forEach((key) => {
      const fieldsObject = getFields(key);
      fieldsObject.forEach((field) => {
        if(field.multiplier && !dividedArray.includes(field.name)) {
          postData[field.name] = parseFloat(postData[key]) / 100;
          dividedArray.push(field.name);
        }
      })
    })

    keysToRefresh.forEach((keyToRefresh) => {
      delete postData[keyToRefresh];
    })

    errorBody = postData;
    
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', "application/json")

    window.defaultsStatus = 'updating';
    window.dispatchEvent(new Event('defaultsStatus'));

    fetch(`${GATSBY_API_URL}/tempest/v1/getdefaults`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(postData),
      signal: abortController.signal // Associate the fetch with the abortController's signal
    })
      .then(response => {
        try {
          if (response.status != 200) {
            window.defaultsStatus = 'error';
            window.dispatchEvent(new Event('defaultsStatus'));
            return null;
          } else {
            return (response.status === 200) ? response.json() : null;
          }
        } catch (e) {
          return;
        }
      })
      .then(data => {
        if (data === null)
          return;
        window.defaultsStatus = 'uptodate';
        window.dispatchEvent(new Event('defaultsStatus'));
        let loadingDivEl = document.querySelectorAll('.loading-div');
        let statusEl = document.querySelectorAll('.statusBar');
        if (loadingDivEl.length > 0) {
          loadingDivEl.forEach((el) => {
            el.classList.add('active');
            try {
              setTimeout(() => statusEl != null ? statusEl[0]?.remove('hide') : null, 1000);
            } catch (e) {
            }
          });
        }
        Object.keys(data).forEach((k) => {
          let dataValue = data[k];
          let fieldObjects = getFields(k);
          let multipliedArray = [];
          fieldObjects.forEach((fieldObj) => {
            if(fieldObj.multiplier && !multipliedArray.includes(fieldObj.name)) {
              multipliedArray.push(fieldObj.name);
              dataValue = dataValue * fieldObj.multiplier;
            }
          });
          data[k] = dataValue;
        });

        setAllFormData(data);
        setFormData((prevFormData) => {
          // Filter out keys that are in window.inputtedValues
          const filteredData = Object.keys(data || {}).reduce((acc, key) => {
              if (keysToSkip.includes(key) && key !== 'state')
                return acc;
              if (!window.inputtedValues.includes(key)) {
                  //Is it hidden? 
                  let fieldObjects = getFields(key);
                  let showen = false;
                  let value = data[key];
                  const multipliedArray = [];
                  fieldObjects.forEach((fieldObj) => {
                    if (!HideIfNoContext(fieldObj.hideifoptions,prevFormData,key))
                      showen = true;

                  });
                  
                  if(showen) {
                    if(!multipliedArray.includes(key)) {
                      acc[key] = value;
                    }
                  }
              }
              return acc;
            if (!window.inputtedValues.includes(key)) {
              //Is it hidden? 
              let fieldObjects = getFields(key);
              let showen = false;
              fieldObjects.forEach((fieldObj) => {
                if (!HideIfNoContext(fieldObj.hideifoptions, prevFormData, key))
                  showen = true;
              });
              if (showen)
                acc[key] = data[key];
            }
            return acc;
          }, {});

          return {
            ...prevFormData,
            ...filteredData,
            defaultsLoaded: true,
            ...filterDataByInputted(prevFormData),
            'rural-zip': data && typeof data['rural-zip'] !== 'undefined' ? data['rural-zip'] : prevFormData['rural-zip'],
            'zip-greater-than-four': data && typeof data['zip-greater-than-four'] !== 'undefined' ? data['zip-greater-than-four'] : prevFormData['zip-greater-than-four'],
            "vehicle_type": data && typeof data['vehicle_type'] !== 'undefined' ? data['vehicle_type'] : prevFormData['vehicle_type'],
            'active-solar-project': data && typeof data['active-solar-project'] !== 'undefined' ? data['active-solar-project'] : prevFormData['active-solar-project'],
            'state': data && typeof data['state'] !== 'undefined' ? data?.state : prevFormData['state'],
            "ashp_calculated_heatloadservedwswitchoff": data && typeof data['ashp_calculated_heatloadservedwswitchoff'] !== 'undefined' ? data["ashp_calculated_heatloadservedwswitchoff"] : prevFormData['ashp_calculated_heatloadservedwswitchoff'],
            "solargenpercentannualdemand": data && typeof data['solargenpercentannualdemand'] !== 'undefined' ? data["solargenpercentannualdemand"] : prevFormData['solargenpercentannualdemand'],
            "solargenpercentannualexported": data && typeof data['solargenpercentannualexported'] !== 'undefined' ? data["solargenpercentannualexported"] : prevFormData['solargenpercentannualexported'],
            "solarcapco_perw": data && typeof data['solarcapco_perw'] !== 'undefined' ? data["solarcapco_perw"] : prevFormData['solarcapco_perw'],
            "solargen": data && typeof data['solargen'] !== 'undefined' ? data["solargen"] : prevFormData['solargen'],
          };
        });
      })
      .catch(error => {
        if (error.message === 'Fetch was aborted') {
          console.log('Previous fetch request was aborted, new request started');
        } else {      
          console.error('Fetch error:', error);
        }
      });

      const errorEmailFunc = () => {
        const errorForm = document.getElementById("error-form");
        const errorFormData = new FormData(errorForm);
        errorFormData.append("body", JSON.stringify(errorBody));
      
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded"},
          body: new URLSearchParams(errorFormData).toString(),
        }).then(() => console.log("Error form successfully submitted")).catch((error) => alert(error));
      }
      
      if(errorTrigger) {
        document.addEventListener("errorsEmail", errorEmailFunc);
        errorTrigger = false;
      }
    };

  const overwriteFormData = data => {
    setFormData(data);
  }

  return { formData, addOrUpdateFormData, clearFormData, addDefault, getDefaultsFromApi, addNameToInputtedValues, removeNameFromInputtedValues, overwriteFormData, replaceFormData, allFormData, clearFormDataNoReload };
};

export default useFormData;
