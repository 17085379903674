import * as React from "react"

import * as styles from './modal.module.scss';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const FaqModal = ({ modalFaqOpen, setFaqModalOpen }) => {
  return (
    <div className={styles.modal + (modalFaqOpen ? ` ` + styles.hidden : '')}>
      <div className={styles.modalMain}>
        <div className={styles.modalHeader}>
            <h2 className={styles.faqsHeader}>Frequently Asked Questions</h2>
            <button className={styles.modalClose} onClick={e => setFaqModalOpen(!modalFaqOpen)}>Close</button>
        </div>
        <div className={styles.modalContent}>
            <article>
            <h3>User Experience</h3>
            <Accordion allowZeroExpanded>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who is this calculator designed for?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The Green Upgrade Calculator is designed primarily for energy professionals – such as home energy contractors, home energy advisors, and residential energy analysts – to perform both economic and climate impact analyses on different combinations of residential green upgrades versus traditional replacements.</p>
                        <p>Home contractors and advisors can confidently communicate economic benefits of green upgrades to clients and customers by quickly and accurately analyzing various system specifications (e.g., the air-source heat pump capacity and efficiency specifications at different temperatures, hybrid heating designs) with real-time electric utility rates.</p>
                        <p>The analytical engine behind this calculator can also be leveraged for regional analyses through the Application Programming Interface (API). Please reach out using the contact form if you are interested in learning more about that work.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are “green upgrades” and “traditional replacements”?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Green upgrades refer to home and transportation improvements that reduce pollution and improve performance. Traditional replacements refer to the home appliance or gasoline vehicle a homeowner would need to purchase when it is time to replace the existing appliance or vehicle. This information is needed for the calculator to compare the impact of the selected green upgrades with traditional replacements.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How are the default optional values determined?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>To limit the number of required data inputs, this calculator automatically estimates default values for a large number of technical, operation and maintenance, upfront cost, and financing inputs based on the user-entered required information and additional assumptions listed in the technical FAQ below and the further information icon next to each default value. For more accurate results, users are encouraged to update these default values with more personalized information (see “Can I enter in additional information to refine the results?”).</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Can I enter in additional information to refine the results?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Yes. Users can go to the "Optional Steps" section to provide personalized inputs. Users are encouraged to use this feature to refine the analysis with hyper-local information, with particular attention to the system size and efficiency, electric utility tariff, up-front costs from contractors, and available incentives and financing.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How can I save or share the calculation results?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The calculator will not automatically save your session details if you close the tab. However, users can select the “Save Analysis” link in the top right and in the "View Results" section. This link can be used to reload an old session or share the results with others.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What is your data privacy policy? Will the information I enter be saved or shared?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>RMI respects your right to privacy, and we are committed to maintaining your confidence and trust. We do not share or sell any information you provide to us. RMI does not collect any information from you without your knowledge and permission, and we do not disclose this information to anyone except as needed to manage our website and database with the help of service providers, who are subject to a nondisclosure agreement. Information entered into this calculator is stored in a secure location and is accessible only by designated staff. For more information, please read RMI's <a href="https://rmi.org/privacy-policy/" target="_blank">Privacy Policy</a>.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Whom can I reach out to for more information or to provide feedback?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Please use the "Contact Us" page.</p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
            <h3>Technical Calculations</h3>
            <h4>General</h4>
            <Accordion allowZeroExpanded>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How often will the calculator be updated? What additions may be added in future versions?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>RMI intends to update the raw data (e.g., default energy rates, up-front costs, incentives, financing terms, electric grid emissions) in this calculator on an annual basis or more frequently where possible. Future improvements to the calculator’s functionality (e.g., additional green upgrades, dynamic inputs, user interface) will be assessed and prioritized based on user feedback among other factors. If you have any feedback or recommendations for future development, please use the "Contact Us" page to submit your thoughts.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Is this calculator an annual or hourly energy model? 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>This calculator estimates hourly energy usage, and corresponding emissions, for each end-use type — space cooling, space heating, water heating, cooking, and all other end uses. See subsequent technical questions for further information on the hourly energy and emissions calculations by end use.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How was this calculator peer reviewed?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Partner organizations reviewed aspects of the technical calculations most relevant to their expertise. The following list represents a subset of these partner organizations and their corresponding expertise:</p>
                        <ul>
                        <li>Electric utility tariffs: Arcadia </li>
                        <li>Rooftop solar and battery storage: Solar United Neighbors</li>
                        <li>Community solar: Solar United Neighbors</li>
                        <li>Space heating and cooling: Northeast Energy Efficiency Partnerships, Pacific Northwest National Laboratory, and Abode Energy Management</li>
                        <li>Water heating: New Buildings Institute</li>
                        <li>Transportation electrification: Electrification Coalition</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What default energy rates are used? What default energy rate escalators are assumed?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The default energy rates are estimated by the following fuel types:</p>
                        <ul>
                        <li>Electricity: The default volumetric rate is the 2023 state average residential electricity rate, sourced from the <a href="https://www.eia.gov/electricity/sales_revenue_price/">Energy Information Administration</a>, and a $12 monthly fixed charge based on average electric utility fixed charges across the country. Users also have the option to select their utility-specific electricity rate, leveraging real-time utility rate tariffs through <a href="https://developer.genability.com/api-reference/">Arcadia’s Signal API</a>.</li>
                        <li>Natural gas: The default volumetric rate is the 2023 state average residential natural gas rate, sourced from the <a href="https://www.eia.gov/dnav/ng/ng_pri_sum_a_epg0_prs_dmcf_a.htm">Energy Information Administration</a>, and a $15 monthly fixed charge based on average electric utility fixed charges across the country.</li>
                        <li>Delivered fuel (i.e., fuel oil and propane): The default fuel oil and propane volumetric rates are the 2022 regional average, sourced from the <a href="https://www.eia.gov/dnav/pet/pet_pri_wfr_a_EPD2F_PRS_dpgal_m.htm">Energy Information Administration.</a> The default gasoline price is the 2023 regional average, sourced from the <a href="https://www.eia.gov/petroleum/gasdiesel/">Energy Information Administration.</a></li>
                        </ul>
                        <p>The default energy rate escalator is the average annual percent change over the past 10 years for electricity and natural gas, and the past 20 years for delivered fuel and gasoline, in the user’s state or region.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Can I use my specific electric utility rates in my savings calculations?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Yes. In the Operation and Maintenance section, under Energy Rates, select your utility and corresponding tariffs from the Electric Utility and Electric Utility Tariff dropdowns. This option uses <a href="https://developer.genability.com/api-reference/">Arcadia’s Signal API</a> to apply the exact, current utility rates to your savings calculation, giving you a more accurate estimate of potential savings.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Which default incentives are included?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The default incentive values include federal and statewide incentives for the corresponding green upgrade and traditional replacement scenario. The federal incentives include the <a href="https://www.irs.gov/credits-deductions/energy-efficient-home-improvement-credit" target="_blank">Energy Efficient Home Improvement Tax Credit (25C)</a>, <a href="https://www.energy.gov/eere/solar/homeowners-guide-federal-tax-credit-solar-photovoltaics" target="_blank">Residential Clean Energy Tax Credit (25D)</a>, and <a href="https://www.energy.gov/scep/home-energy-rebates-programs" target="_blank">Home Energy Rebate Program</a>. Where applicable, the incentives are modified based on the unit’s efficiency (noted in the Technical Details section) and user income category (noted in the Up-front Costs and Incentives section). Local or utility incentives are not included at this time by default but can be added by the user.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Are the economic results discounted to account for the time value of money?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>No. This first version does not use a discount rate to discount future cash flows based on an assumption that most residents think more commonly in nominal terms but it is planned to incorporate a discount rate input in a future version.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How are the operating emissions for electric technologies calculated? How are they forecasted over the life of the analysis?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The default hourly electric grid emissions rate is determined using the National Renewable Energy Laboratory’s 2023 <a href="https://www.nrel.gov/analysis/cambium.html" target="_blank">Cambium long-run marginal emissions rate month-hour data set</a>, which is averaged for each hour of each month, by state, for each year from 2024 to 2050.</p>
                        <p>To enable users to override the default electric grid emissions rate projections, there are two separate user inputs: the “State Annual Average Marginal Grid Intensity” and “Average Annual Grid Intensity Percent Reduction over the Analysis.” If the user overrides the default state annual average marginal grid intensity, the percent change from the default value is applied to the first year of the month-hour data set. If the user overrides the default percent average annual emissions reduction over the analysis input, the percent change from the default value is applied to the subsequent annual forecasts.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <h4>Home</h4>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How is the default current annual energy consumption by end use determined?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The default current annual energy consumption (i.e., how much energy is used) by end use prior to green upgrades or traditional replacements is determined through the following ways:</p>
                        <ul>
                        <li>“Space heating” and “space cooling” total annual energy load is initially based on Energy Information Administration (EIA) <a href="https://www.eia.gov/consumption/residential/data/2020/index.php?view=microdata" target="_blank">Residential Energy Consumption (RECS) 2020 consumption microdata</a> given the user-entered home age, home type, home size, and climate zone, and dataset-reported appliance and corresponding efficiencies. Space-heating and cooling energy load is then adjusted based on any additional weatherization measures (further explained in the question “How are the weatherization load reductions determined?”). Finally, the space-heating and space-cooling consumption is estimated based on the user’s current appliance efficiency.</li>
                        <li>“Water-heating” and “cooking” load is initially based on Energy Information Administration (EIA) Residential <a href="https://www.eia.gov/consumption/residential/data/2020/index.php?view=microdata" target="_blank">https://www.eia.gov/consumption/residential/data/2020/index.php?view=microdata</a> given the user-entered home type, home size, and climate zone, and dataset-reported appliance and corresponding efficiency. The current water-heating and cooking consumption is estimated based on the user’s current water heater and cooking appliance efficiency.</li>
                        <li>“All other end uses” total annual energy consumption is based on Energy Information Administration (EIA) <a href="https://www.eia.gov/consumption/residential/data/2020/index.php?view=microdata" target="_blank">Residential Energy Consumption (RECS) 2020 consumption microdata</a> given the user-entered home type, home size, and climate zone.</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How is the hourly energy consumption by end use determined for the traditional and green upgrade scenarios?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The traditional hourly energy consumption by end use is generated through three steps:</p>
                        <ol>
                        <li>The calculator first selects appropriate hourly energy consumption profile shapes by end use from the National Renewable Energy Laboratory’s (NREL) <a href="https://data.openei.org/s3_viewer?bucket=oedi-data-lake&prefix=nrel-pds-building-stock%2Fend-use-load-profiles-for-us-building-stock%2F2021%2Fresstock_tmy3_release_1%2Ftimeseries_individual_buildings%2F" target="_blank">ResStock</a> for the corresponding climate zone and home type.</li>
                        <li>The hourly energy consumption profile shapes for space-heating, space-cooling, water-heating, cooking, and all other end uses are then adjusted linearly to match the user-estimated current annual energy load or consumption by end use (see “How is the default current annual energy consumption by end use determined?”).</li>
                        <li>If a traditional replacement is selected, the new space-heating, space-cooling, water-heating, or cooking hourly consumption is then adjusted based on the hourly efficiency of the user’s current appliance and the traditional replacement efficiency.</li>
                        </ol>
                        <p>In general, the green upgrade hourly energy consumption by end use is generated by dividing its hourly load by the green upgrade’s hourly efficiency. While the hourly load is constant between the traditional and green upgrade scenarios, the green upgrade hourly load for space-heating and -cooling is adjusted to incorporate any related weatherization improvements.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How are the weatherization load reductions determined?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Weatherization upgrades are organized into three categories: wall and ceiling insulation, air sealing, and window replacement. For any weatherization upgrade, an annual percent reduction in heating and cooling load is calculated by estimating the pre- and post-weatherization annual heating and cooling season load for the attic or ceiling, walls, floor, windows, infiltration, people, and equipment.</p>
                        <p>For attic or ceiling, wall, and floor, the before and after heat transfer values are estimated by dividing the heat transfer area by the R value and then multiplying by the heating degree days for heat load and cooling degree days for cooling load.</p>
                        <p>For windows, the before and after heat transfer values are estimated by dividing the window heat transfer area by the window’s R value and then multiplying by the heating degree days for heat load and cooling degree days for cooling load. The estimated solar heat gain is then subtracted from this window heat transfer value in the heating season and added during the cooling season. The solar heat gain is estimated by multiplying the estimated solar insolation during the corresponding heating or cooling season by the window area and the assumed solar heat gain coefficient, with different assumed values in the summer and winter months and northern and southern locations.</p>
                        <p>For infiltration, the before- and after-air sealing sensible heat values are estimated by multiplying the air changes per hour, estimated using the Princeton method to divide the ACH50 value by 20, by the home’s air volume, air density, and air heat capacity, and heating degree days in winter and cooling degree days in summer. In summer, the latent heat values are estimated by multiplying the air changes per hour by the home’s air volume, air density, and air heat capacity, seasonal total difference between indoor and outdoor specific humidity, and the enthalpy of evaporation. The seasonal total difference in indoor and outdoor specific humidity is estimated by estimating hourly indoor and outdoor specific humidity using the Magnus formula and the hourly relative humidity values in the user’s climate zone, sourced from the National Renewable Energy Laboratory’s TMY3 data set.</p>
                        <p>The attic or ceiling, walls, floor, windows, and infiltration values are normalized to the user’s annual space-heating and cooling load input. </p>
                        <p>Internal heat gain from people is estimated based on two people at rest, each person giving off 210 Btu per hour of sensible heat in the winter and summer and 140 Btu per hour of latent heat in the summer based on <a href="https://www.ashrae.org/technical-resources/ashrae-handbook/ashrae-handbook-online" target="_blank">ASHRAE fundamentals</a>, in their home during two-thirds of the corresponding heating and cooling hours. Internal heat gain from indoor equipment is estimated through the user’s annual energy usage for cooking and “all other end uses.”</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How is the default air-source heat pump size determined?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The default air-source heat pump size, and number of outdoor units, is first based on the user selecting a Whole-Home Heat Pump or Hybrid Heat Pump. In warm and mild climates (i.e., climate zones less than or equal to 3), a Whole-Home Heat Pump is automatically used and sized to meet more than 90% of the annual heating load with electric resistance for backup heating. For Hybrid Heat Pump analysis, the air-source heat pump size is determined by meeting the maximum of the peak cooling load. The “For reference…” sentence below the “Size per Outdoor Unit (tons)” input provides further information.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How is the air-source heat pump and air conditioner hourly heating and cooling capacity and efficiency determined? Is it derated for real-world performance?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>First, the calculator generates an air-source heat pump performance curve, or trendline, for its minimum, average, and maximum capacity and efficiency versus outdoor air temperature. This performance curve is based on the manufacturer-reported data for an average subset of 100 common air-source heat pumps listed in the Northeast Energy Efficiency Partnerships’ <a href="https://ashp.neep.org/" target="_blank">Air Source Heat Pump (ASHP) database</a>, with the subset based on the user-selected ASHP size and ducting type. These performance curves are adjusted linearly based on any user override in capacity or efficiency (e.g., increasing the heating efficiency curve by 10% if a user overrides HSPF2 from 9.5 to 10.5), and derated by 15% to estimate reductions for real-world performance based on conversations with regional energy efficiency organizations. These performance curves are then applied to the hourly TMY3 temperature data in the user’s climate zone. The supplemental heating element then covers any unmet heating load.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How is the electric heat pump water heater hourly efficiency determined? It is derated for real-world performance?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>First, the calculator generates a performance curve, or trendline, for the electric heat pump compressor’s capacity and efficiency versus ambient air temperature. This performance curve is based on reported data from two common electric heat pump water heater manufacturers as found in <a href="https://github.com/EcotopeResearch/HPWHsim/blob/master/src/HPWHpresets.cc" target="_blank">HPWHSim</a>; these data are also used in <a href="http://www.bwilcox.com/BEES/BEES.html" target="_blank">CBECC-Res</a>. These performance curves are then derated by 15% to account for reductions for real-world performance. These performance curves are then applied to the hourly ambient temperature surrounding the water heater based on the selected water heat location (e.g., living space, basement, garage, outside) and the user’s climate zone. If “Hybrid” is selected for the operating mode, the electric resistance element then covers any unmet water-heating load based on the user’s hourly water-heating load profile.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How are the efficiencies of the cooking appliances determined?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The efficiency values for stovetops are based on <a href="https://www.energystar.gov/partner_resources/brand_owner_resources/spec_dev_effort/2021_residential_induction_cooking_tops" target="_blank">Energy Star’s residential induction cooking top research</a> and those for ovens are based on <a href="https://www.energystar.gov/products/commercial_ovens" target="_blank">Energy Star’s commercial oven ratings</a> as a proxy given a lack of residential oven ratings.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How is hourly solar generation determined? How is excess solar generation determined and valued?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The National Renewable Energy Laboratory’s (NREL’s) <a href="https://developer.nrel.gov/docs/solar/pvwatts/" target="_blank">PVWatts API</a> is leveraged to generate hourly solar generation based on the user’s zip code as well as the default or user-provided values for system size, tilt angle, tilt azimuth, and degradation factor. For all other variables required by PVWatts, the calculator accepts the NREL default. The hourly solar generation is compared with the hourly residential load in the green upgrade scenario to determine the reduction in hourly residential load and hourly excess solar generation.</p>
                        <p>The economic value of the reduction in hourly load is determined by using either (1) the state average residential rate or (2) the hourly residential rate based on the selected electric utility rate. If the user selects a specific electric utility, the economic value of hourly excess solar generation is determined based on the export rate for that utility. Otherwise, the export rate is calculated as a state average, which is determined based on an assessment of state regulations that fall into four categories: (1) full retail rate, (2) less than retail rate, (3) avoided cost rate, and (4) no compensation.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How are the default traditional and green home upgrade upfront costs determined?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The default traditional and green home upgrade upfront costs are estimated using different methodologies summarized below based on available data.</p>
                        <ul>
                        <li>Rooftop solar and battery storage: Rooftop solar default upfront costs are based on the state average relative residential solar panel cost for a 5 kW system in 2023, sourced from <a href="https://www.energysage.com/" target="_blank">energysage.com,</a> and then adjusted based the user’s solar panel system size using a trendline of national average installed costs by system size, sourced from <a href="https://emp.lbl.gov/tracking-the-sun" target="_blank">Lawrence Berkeley National Lab.</a> Battery storage upfront costs are based on Tesla Powerwall material costs, based on December 2023 pricing, corresponding to the battery storage system size selected and corresponding labor installation.</li>
                        <li>Space heating and cooling: Default upfront costs are estimated based on data and feedback from various home energy contractors and local electrification program advisors given the technology size and efficiency. These national estimates are then adjusted by state-specific RS Means material and labor indexes to estimate state cost variation.</li>
                        <li>Weatherization: Default upfront costs are estimated based on a trendline of installed costs for each weatherization measure type based on the National Renewable Energy Lab’s Residential Efficiency Measures Database for <a href="https://remdb.nrel.gov/measures?group_id=10&component_type_id=376" target="_blank">air leakage,</a> <a href="https://remdb.nrel.gov/measures?group_id=12&component_type_id=184" target="_blank">walls,</a> and <a href="https://remdb.nrel.gov/measures?group_id=16&component_type_id=190" target="_blank">windows</a> and corresponding ACH50, wall insulation R value, and window R value inputs. These national estimates are then adjusted by state-specific RS Means material and labor indexes to estimate state cost variation.</li>
                        <li>Water heating: Default upfront costs are estimated based on data and feedback from various home energy contractors, electrification program advisors, and retail prices (e.g., Home Depot and Lowes) given the technology size, efficiency, and original fuel source. These national estimates are then adjusted by state-specific RS Means material and labor indexes to estimate state cost variation.</li>
                        <li>Cooking appliance upfront costs: Default upfront costs are estimated based on costs reported from <a href="https://www.homedepot.com/" target="_blank">Home Depot</a> and <a href="https://www.lowes.com/" target="_blank">Lowe’s.</a> State-specific RS Means values were not utilized assuming standardization in pricing and selfinstallation.</li>
                        <li>Additional home upgrades: Default upfront costs are estimated based on the <a href="https://www.homeadvisor.com/cost/electrical/upgrade-an-electrical-panel/" target="_blank">electrical panel upgrade,</a> <a href="https://www.homeadvisor.com/cost/roofing/install-a-roof/" target="_blank">reroofing,</a> and <a href="https://www.homeadvisor.com/cost/environmental-safety/remove-asbestos/" target="_blank">health and safety</a> upfront cost estimates from HomeAdvisor.com. These national estimates are then adjusted by state-specific RS Means material and labor indexes to estimate state cost variation.</li>
                        </ul>

                        <p>It is important to note that these default values are solely to provide an initial high-level estimate and users should enter in more precise upfront cost information from contractor quotes.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <h4>Transportation</h4>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How is the percentage of miles driven using the battery for a plug-in hybrid vehicle calculated?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The miles driven on the battery versus gasoline engine is grounded in an <a href="https://theicct.org/publication/real-world-phev-us-dec22/" target="_blank">International Council on Clean Transportation (ICCT)</a> study that used consumer self-reported data and data collected by the California Bureau of Automotive Repair. The frequency of electric charging has a major impact on the percentage of miles driven on battery. Thus, these values were adjusted by utilizing charging simulation data from the <a href="https://theicct.org/wp-content/uploads/2021/06/PHEV-white-paper-sept2020-0.pdf" target="_blank">ICCT</a> to estimate the impact the presence of a Level 2 electric vehicle charger in the driver’s home would have on the electric driving share. For users that specify a Level 2 charger, the calculator assumes an individual would fully charge their vehicle once per driving day.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How is the battery efficiency and range adjusted based on climate conditions?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Electric vehicle batteries operate less efficiently in extreme hot and cold conditions. The model utilizes hourly temperature data in the user’s selected climate zone during the driving hours of 6 a.m.–8 p.m., along with a <a href="https://www.geotab.com/blog/ev-range/" target="_blank">data set</a> on real-world electric range for all temperatures between −30°F and 120°F, to apply a temperature-induced average annual efficiency change. This change is applied to the vehicle’s battery efficiency and electric range.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How is the charging speed for an electric vehicle or electric bike estimated? How is this used for the vehicle’s hourly charging profile?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The model assumes the user drives the electric vehicle or electric bike an equal number of miles each day and the vehicle is charged each night. The charging speed for the vehicle is dependent on whether the user indicates they are utilizing a Level 1 or Level 2 charger. The power output for the Level 1 charger is calculated to be 1.44 kW based off a 120-volt and 12-amp charger. The power output for the Level 2 charger is calculated to be 9.60 kW based off a 240-volt and 40-amp charger. The user’s hourly vehicle charging profile is informed by the energy consumption required to charge the vehicle on a daily basis. The vehicle is assumed to be charged each night beginning at 8 p.m. until the battery is fully recharged.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Why is there an electric bike battery replacement cost? How is this cost calculated?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p><a href="https://pedegoelectricbikes.ca/3-ways-to-prolong-your-electric-bike-battery/" target="_blank">Industry research</a> suggests electric bike batteries need to be replaced after around five years of consistent usage. To accurately assess the total cost of ownership of an electric bike, the model includes this replacement cost, which is calculated by multiplying the watt-hours for the battery of the selected electric bike by an <a href="https://ebikeshq.com/electric-bike-battery-cost/" target="_blank">average battery replacement cost</a> of $1.42/Wh.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How are the default modal split among public transit, electric bike, ride-hailing, carsharing, and walking/bicycling determined for the car-free scenario?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>The car-free scenario utilizes Replica data, which provides the proportion of total trips taken by individual modes for every zip code in the United States. These mode shares were then grouped for urban, suburban, and rural zip codes and serve as the default values for the user.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How is the location identified as an urban, suburban, or rural zip code?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Zip codes are determined to be urban, suburban, or rural based on a <a href="https://www.fourfront.us/data/datasets/us-population-density/" target="_blank">population density</a> data set. zip codes are considered urban if they contain more than 1,000 people per square kilometer, suburban if they contain between 300 and 1,000 people per square kilometer, or rural if they contain fewer than 300 people per square kilometer.</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How are the operating costs calculated for the car-free scenario?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                        <li>Public Transit: The scenario assumes the user will purchase a public transit monthly pass, which will meet their needs for each month. The model includes data for the transit agencies serving the 100 most populated counties in the United States. To account for outliers, several additional transit agencies were included to ensure there was at least one transit system serving all 50 states and Washington, D.C. If there is no transit system servicing the user’s county, the model assumes an average cost of all transit agencies in the state. The model includes data for low-moderate-income transit passes if they are available for the selected agency.</li>
                        <li>Ride-hailing: The model assumes a cost of $2.50 per mile based on research from the <a href="https://www.vtpi.org/tca/tca0501.pdf" target="_blank">Victoria Transport Policy Institute.</a>. There are no additional energy costs attributed to the user as these are paid by the driver.</li>
                        <li>Car Sharing/Rentals: The model assumes a base cost of $90 per day based on research from the <a href="https://www.vtpi.org/tca/tca0501.pdf" target="_blank">Victoria Transport Policy Institute.</a> It is assumed that, on average, 125 miles are driven per car rental. The fuel and electricity costs associated with the mileage driven are added to the total daily cost.</li>
                        <li>Electric Bicycle: The costs for charging an electric bicycle are paid by the user. In addition, the model assumes a bicycle maintenance cost of $0.10 per mile. This value was sourced from various websites and is based on the average cost for replacing bicycle tires and a bicycle chain plus completing a tune-up annually. Last, the model includes the cost of replacing the electric bicycle’s battery every five years (see “Why is there an electric bike battery replacement cost? How is this cost calculated?”).</li>
                        <li>Walking/Bicycling: The model assumes a bicycle maintenance cost of $0.10 per mile. This value was sourced from various websites and is based on the average cost for replacing bicycle tires and a bicycle chain plus completing a tune-up annually.</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How are the emissions calculated for the car-free scenario?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                        <li>Public Transit: Zero operating emissions are assumed because the greenhouse gas emissions for operating the transit vehicle are not attributed to the individual user.</li>
                        <li>Ride-hailing: The operating emissions for ride-hailing are derived from gasoline or electricity consumption for charging the vehicle. Due to deadheading, or empty trips, which refers to the time when ride-hailing drivers have no passengers in the vehicle, all ride-hailing trips incur a <a href="https://www.ucsusa.org/sites/default/files/2020-02/Ride-Hailings-Climate-Risks-Methodology_0.pdf" target="_blank">42% increase</a> in mileage and associated emissions.</li>
                        <li>Car Sharing/Rentals: The operating emissions for car sharing and rentals are calculated based on the gasoline usage or electricity consumption.</li>
                        <li>Electric Bike: The operating emissions for an electric bicycle are based on the electricity for charging the bicycle. The model also considers manufacturing emissions for the production of the electric bicycle along with the replacement of the battery.</li>
                        <li>Walking/Bicycling: Zero operating emissions are assumed for these modes as they are human-powered.</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How do you calculate operational costs if a user chooses to purchase an electric vehicle and shift a portion of their miles to an electric bike or public transit?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Under these scenarios, a default portion of miles traveled by vehicle are shifted to other modes.</p>
                        <ul>
                        <li>For electric bikes, the portion of miles traveled is based off the percentage of short trips the user is willing to take by electric bike. The default value is based on an internal estimate of potential mode shift from vehicles to e-bikes using data from RMI’s E-Bike Environment and Economic Impact Assessment <a href="https://rmi.org/insight/e-bike-environment-and-economics-impact-assessment-calculator/" target="_blank">Calculator</a>.</li>
                        <li>For transit, the default portion of miles traveled is based on Replica data for the user’s zip code.</li>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
            </article>
        </div>
      </div>
    </div>
  );
};

export default FaqModal